import env from './.env';
import { IEnv } from './Ienv';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const s3FolderBase = `consumer/dev/portal`;
//change the below accountkit url pointing to localhost port, if api services are running in localhost
const Accountkit = `https://accountkit-qaapi.zapopen.com`;
const VoiceVideo = `https://voicevideocall-qaapi.zapopen.com`;
const Log = `https://log-qaapi.zapopen.com`;
const Devtestapp = `https://devtestapp-local.azurewebsites.net`;
const CellGateConnector = `https://qaapi-cellgate-connector.azurewebsites.net`;
//const CellGateConnector = `http://localhost:7269`;
const OpenpathConnector = `https://qaapi-openpath-connector.azurewebsites.net`;
//const OpenpathConnector = `http://localhost:7268`;
const DataLayer = "https://qaapi-partner-datalayer.azurewebsites.net";
//const DataLayer = "https://localhost:7000";
export const UserPortal = `https://user-qaapi.zapopen.com`;
export const environment: IEnv = {
  version: env.app_version,
  build_version: env.app_build_version,
  isVersionCheck: false,
  production: true,
  mode: 'dev',
  SITE_NAME: '',
  COPYRIGHT_TEXT: '©2020',
  DEFAULT_PAGINATION_LIMIT: 10,
  USER_PORTAL: UserPortal,
  API_URL: {
    Accountkit: {
      url:`${Accountkit}`,
      login: `${Accountkit}/api/Dealer/Login`,
      getDropdownLocations: `${Accountkit}/api/Dashboard/GetMapLocations?userId=`,
      resetPassword: `${Accountkit}/api/Dealer/ResetPassword`,
      forgotPassword: `${Accountkit}/api/Dealer/ForgotPassword`,
      validateResetPasswordLink: `${Accountkit}/api/Dealer/validateResetPasswordLink?passwordResetToken=`,
      createDealerAdmin: `${Accountkit}/api/Dealer/CreateDealerAdmin`,
      getDealerList: `${Accountkit}/api/Dealer/GetDealerUserList`,
      getDealerUserListForAdmin: `${Accountkit}/api/Dealer/GetDealerUserListForAdmin`,
      updateDealerPassword: `${Accountkit}/api/Dealer/UpdatePassword`,
      searchAccountsForDealer: `${Log}/api/Dealer/SearchAccountsForDealer`,
      getTimezones: `${Accountkit}/api/Definition/GetTimezones?sort=`,
      getPropertyLevelType: `${Accountkit}/api/AccountDetail/GetPropertyLevelTypes`,
      // getCountry:`${Accountkit}/api/AccountDetail/GetPropertyLevelTypes`,
      getTierService: `${Accountkit}/api/TierService/TierLevelsDDL`,
      getDealers:`${Accountkit}/api/Dealer/List`,
      deleteDealer:`${Accountkit}/api/Dealer/DeleteDealer?dealerId=`,
      saveDealerUser:`${Accountkit}/api/Dealer/CreateDealerAdmin`,
      searchAccounts:`${Accountkit}/api/Dealer/GetDealerAccounts`,
      getCompanyDetails:`${Accountkit}/api/Definition/GetCompaniesDetails`,
      getTimezoneID:`${Accountkit}/api/UserDetails/getTimezoneByID?timezoneID=`
    },
    VoiceVideo: {
      url:`${VoiceVideo}`,
      getCountries: `${VoiceVideo}/api/Group/GetGroupInfo`,
      getDealerPartner: `${VoiceVideo}/api/dealer-partners?dealerUserID=`,
      getDealerAccount: `${VoiceVideo}/api/dealer-accounts?dealerID=`,
      getSyncPartnerUser: `${VoiceVideo}/api/sync-partner-users`,
      getUsersforExport: `${VoiceVideo}/api/dealer-users`,
      savePartnerUsers: `${VoiceVideo}/api/sync-partner-users`,
      getAccountKits: `${VoiceVideo}/api/kits`,
      getUsersGroup: `${VoiceVideo}/api/accounts/`,
      postimportkits: `${VoiceVideo}/api/kits`,
      getSyncPartnerKit: `${VoiceVideo}/api/sync-partner-kits`,
      getPartnerURL: `${VoiceVideo}/api/partner-url`,
      getPartnerEntities: `${VoiceVideo}/api/partner-entities`
    },
    Log: {
      url: `${Log}`,
      createUser: `${Log}/api/Group/GetGroupInfo`,
      getDealerLogs: `${Log}/api/Dealer/GetDealerAuditLogs`
    },
    Devtestapp: {
      login: `${Devtestapp}/api/login`,
      getOpenPathUsers: `${Devtestapp}/api/dealers/`,
      postAcus: `${Devtestapp}/api/acus`,
    },
    CellGateConnector: {
      migrateUserstoCellgate: `${CellGateConnector}/api/cellgate-user`,
      migrateUserstoPartner: `${CellGateConnector}/api/users`,
      getOpenpathKits: `${CellGateConnector}/api/dealers/`,
      getPartnerUsers: `${CellGateConnector}/api/dealers/`,
      migrateKitstoPartner: `${CellGateConnector}/api/partner-kits`,
      migrateKitstoCellGate: `${CellGateConnector}/api/cellgate-kits`,
      loginToPartner: `${CellGateConnector}/api/partner-login`,
      fetchOpenPathUserGroups: `${CellGateConnector}/api/openpath-user-groups`
    },
    OpenpathConnector: {
      getOpenpathUsers: `${OpenpathConnector}/api/dealers/`,
      getOpenpathKits: `${OpenpathConnector}/api/dealers/`
    },
    DataLayer: {
      getCountries: `${DataLayer}/api/Group/GetGroupInfo`,
      getDealerPartner: `${DataLayer}/api/dealer-partners?dealerUserID=`,
      getDealerAccount: `${DataLayer}/api/dealer-accounts?dealerID=`,
      getSyncPartnerUser: `${DataLayer}/api/sync-partner-users`,
      getUsersforExport: `${DataLayer}/api/dealer-users`,
      savePartnerUsers: `${DataLayer}/api/sync-partner-users`,
      getAccountKits: `${DataLayer}/api/kits`,
      getUsersGroup: `${DataLayer}/api/accounts/`,
      postimportkits: `${DataLayer}/api/kits`,
      getSyncPartnerKit: `${DataLayer}/api/sync-partner-kits`,
      getPartnerURL: `${DataLayer}/api/partner-url`,
      getPartnerEntities: `${DataLayer}/api/partner-entities`,
      getOpenPathUserGroups: `${DataLayer}/api/openpath-groups`,
      getPartnerIntegrationStatus: `${DataLayer}/api/dealers/`,
      getUserGroupsForMapping: `${DataLayer}/api/user-groups-mapping`,
      getUserGroupMappings: `${DataLayer}/api/partner-usergroups-mapping`,
      savePartnerUserGroupMapping: `${DataLayer}/api/partner-usergroups-mapping`,
      fetchCgUserGroupUsers: `${DataLayer}/api/cellgate-group-users`,
      fetchPartnerUserGroupUsers: `${DataLayer}/api/partner-group-users`,
      saveUserToken: `${DataLayer}/api/partner-tokens`,
      fetchOpenPathUsersWithoutGroup: `${DataLayer}/api/partner-custom-users`,
      saveMigrationProcess: `${DataLayer}/api/migration-process`,
      getProcessedCount: `${DataLayer}/api/migration-process-count`,
      getMigrationStatus: `${DataLayer}/api/migration-status`,
      getDealerMigrationStatus: `${DataLayer}/api/dealer-migration-status/`,
      removeGroupMappings: `${DataLayer}/api/partner-usergroups-mapping`,
      saveOrUpdatePartnerDealerToken: `${DataLayer}/api/partner-dealer-tokens`,
      saveGroupMappingAndSyncUsers: `${DataLayer}/api/dealer-sync-users`,
      fetchMigrationCounts: `${DataLayer}/api/user-migration-counts`,
      validateGroupAccess: `${DataLayer}/api/group-access`,
      removeGroupMapping: `${DataLayer}/api/group-mapping`,
      partnerAuthentication: `${DataLayer}/api/partner-authentication`,
      getPartnerCallgroupEntities: `${DataLayer}/api/partner-callgroup-entities`,
      getProperties: `${DataLayer}/api/properties`,
      getCellgateResidentGroups: `${DataLayer}/api/cellgate-resident-groups`,
      getCellgateResidentNoCallGroups: `${DataLayer}/api/cellgate-resident-nocallgroups`,
      createResidentMigration: `${DataLayer}/api/partner-residents-migration`,
      getUserIdForDealer: `${DataLayer}/api/get-userid-fordealer`,
      completeYardiMigration: `${DataLayer}/api/yardi-complete-migration`,
      getPartnerMigrationConfigurations: `${DataLayer}/api/partner-migration-configuration`,
      getUnitDefinitionTypes: `${DataLayer}/api/unitdefinitiontypes`,
      getCallgroupDefinitionTypes: `${DataLayer}/api/callgroupdefinitiontypes`,
      getCallgroupNamesTypes: `${DataLayer}/api/callgroupnametypes` ,
      saveStatusMigrationForEnabledAccount: `${DataLayer}/api/account-migration-status`,
      partnerResidentMigrationStatus:`${DataLayer}/api/partner-resident-migration-status`,
      validateBeforeMigration:`${DataLayer}/api/migration/validate`,
      getRealPageProperties: `${DataLayer}/api/realpage/dealer-get-propertylist`,
      completeRealPageMigration: `${DataLayer}/api/realpage/realpage-complete-migration`,
      getphoneNumberdefinitions: `${DataLayer}/api/partner-phoneNumber-definitions?partnerID=`,
    }
  }
};
