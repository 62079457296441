import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';

import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { AppHeaderComponent } from './layout/header/header.component';


// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const MY_DECLARATIONS = [];

export const UTIL_MODULES = [
  NgxPaginationModule,
  ToastNoAnimationModule,
  ModalModule.forRoot(),
  ToastrModule.forRoot({
    toastComponent: ToastNoAnimation,
    maxOpened: 1,
    autoDismiss: true
  })
];

export const APP_RESOLVER_PROVIDERS = [];
