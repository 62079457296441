export default {
  npm_package_version: '1.0.0',
  app_version: '1.1.3',
  app_build_version: 'V1.0_date_time_tag',
  cognitoSettings: {
    IdentityPoolId: 'replace_identity_pool_id_tag',
    UserPoolId: 'replace_user_pool_id_tag',
    ClientId: 'replace_client_id_tag',
    region: 'replace_region_tag'
  },
  s3AccessKeyId: 'replace_s3_access_key_id_tag',
  s3SecretAccessKey: 'replace_s3_secret_access_key_tag',
  s3Region: 'replace_s3_region'
};
