// #docregion token
import { InjectionToken } from '@angular/core';
import { IAppConfig } from './IApp.config';
export { IAppConfig } from './IApp.config';
import { environment } from '../../environments/environment';
import { APP_ROLES_CONSTANT } from './app.constant';
export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
// #enddocregion token
// #docregion config
export const APP_DI_CONFIG: IAppConfig = {
  site_name: environment.SITE_NAME,
  copyright_text: environment.COPYRIGHT_TEXT,
  version: environment.version,
  isVersionCheck: environment.isVersionCheck,
  build_version: environment.build_version,
  appLogo: 'assets/images/logo.svg',
  DEFAULT_PAGINATION_LIMIT: environment.DEFAULT_PAGINATION_LIMIT || 10,

  UserRoleOptions: [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Customer' }
  ],
  UserRoleConstant: APP_ROLES_CONSTANT,
  UserRoleConstantMap: { 1: 'SuperAdmin', 2: 'Staff', 3: 'Customer' },

  spinnerOptions: {
    type: 'line-scale',
    size: 'medium',
    bdColor: 'rgba(255, 255, 255, 0.3)',
    color: '#8ca4ef'
  },

  dtOptions: {
    paging: true,
    lengthChange: false,
    search: false,
    searching: false,
    serverSide: true,
    processing: false,
    pageLength: 20,
    responsive: true,
    pagingType: 'full',
    autoWidth: false,
    ordering: false,
    language: {
      info: "<div style='display:flex;justify-content:space-between'><div class='col-md-3'>_START_  -  _END_  of  _TOTAL_ </div><div style='float:right;'>_PAGE_  / _PAGES_ </div></div>",
      emptyTable: 'No records found',
      thousands: '',
      paginate: {
        first: '<i class="fa fa-angle-double-left" style="font-size: 14pt;">',
        last: '<i class="fa fa-angle-double-right" style="font-size: 14pt;">',
        previous: '<i class="fa fa-angle-left" style="font-size: 14pt;">',
        next: '<i class="fa fa-angle-right" style="font-size: 14pt;">'
      },
    },
      drawCallback: (settings: any) => {
      if (Math.ceil((settings.fnRecordsDisplay()) / settings._iDisplayLength) > 1) {
        $('.dataTables_paginate').show();
        $('.dataTables_info').show();
        $('.dataTables_empty').hide();
      }
      else {
        $('.dataTables_paginate').hide();
        $('.dataTables_info').hide();
        $('.dataTables_empty').hide();

      }
    },
  }
};
