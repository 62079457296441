import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationModel } from './authentication.model';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
// import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import { catchError } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { APP_CONFIG, IAppConfig } from '../../config/app.config';
import { AdminLocalStorageService } from './admin.local.storage.service';
import { UserService } from 'src/app/shared/services/http';
import { ApiHandler } from '../services/api.handler';

export interface Credentials {
  // Customize received credentials here
  user: AuthenticationModel;
  token: string;
}

const credentialsKey = 'credentials';
const userkey = 'user';

@Injectable({
  providedIn: 'root'
})
export class Authenticationservice {
 
  constructor(
    private http: HttpClient,private apiHandler: ApiHandler,
     private notificationService: NotificationService, private userService:UserService,
    private _localStorage: AdminLocalStorageService,
    @Inject(APP_CONFIG) public config: IAppConfig
  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
    }
  }
  
 

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this._localStorage.setCredentials();
    this._localStorage.setUser();
    return of(true);
  }

  login(_context: any): Observable<Credentials> {
     return this.http
      .post<any>(environment.API_URL.Accountkit.login, {
        username: _context.username,
        password: _context.password,
        LoginAsAdmin:_context.LoginAsAdmin,
        LoginAsId:_context.LoginAsId
      })
      .pipe(
        map((res) => {            
          if (res.IsSuccess) {
            let obj = {
              authToken: res.SessionID,
              authKey: res.AuthToken
            };
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            this.userService.changeUserDetail(res);  //res.user
            this._localStorage.setCredentials(obj);
            this._localStorage.setUser(res);
          }
          return res;
        })
      )
      .pipe(
        // catchError((e: { error: { message: any; error_info: any; }; }) => {
        //   e.error.message = e.error.message ? e.error.message : 'Faile to login.';
        //   this.notificationService.notifyError(e.error.error_info ? e.error.error_info : e.error.message);
        //   return of(false);
        // })
      );
  }
  ValidateUserName(data:any)
  {
    return this.apiHandler.
    post(environment.API_URL.VoiceVideo.url+"/api/Dealer/IsUsernameValid", data)
    .pipe(
      map((respose) => {
          return respose;
      }))
    .pipe(catchError(this.handleError));
  }

  public getToken(): string | null {
    return this._localStorage.getToken();
  }
  public getKey(): string | null {
    return this._localStorage.getKey();
  }
  public isAuthenticated(): boolean {
    const token = this._localStorage.getToken();
    return token != null;
  }

  public getloggedInUser() {
    // const savedCredentials = this._localStorage.getCredentials()
    const savedCredentials = this._localStorage.getCurrentUser();

    return savedCredentials ? savedCredentials : null;
  }

  getUserRole(): Observable<string> {
    const savedCredentials = this.getloggedInUser();
    return of(savedCredentials['role']);
  }
  isSuperAdmin() {
    const savedCredentials = this.getloggedInUser();
    if (!savedCredentials) {
      return false;
    }
    if (savedCredentials['role'] === this.config.UserRoleConstant.SuperAdmin) {
      return true;
    }
    return false;
  }
  getUserPermittedModules() {
    return this._localStorage.getCurrentUserPermittedModules();
  }

//   minimizeSidebar(minimize = true) {
//     const credentials = this._localStorage.getCredentials();
//     const savedCredentials = credentials ? JSON.parse(credentials) : null;
//     if (!savedCredentials) {
//       return null;
//     }

//     if (minimize) {
//       savedCredentials.sidebar_minimize = minimize;
//     } else {
//       delete savedCredentials['sidebar_minimize'];
//     }

//     this._localStorage.setCredentials(savedCredentials);
//   }
  isSidebarMinimized() {
    const savedCredentials = this._localStorage.getCurrentUser();
    if (!savedCredentials) {
      return null;
    }
    return this._localStorage.getSidebarMinimized();
  }

//   saveCurrentLang(lang:any) {
//     const credentials = this._localStorage.getCredentials();
//     const savedCredentials = credentials ? JSON.parse(credentials) : null;
//     if (!savedCredentials) {
//       return null;
//     }

//     if (lang) {
//       savedCredentials['lang'] = lang;
//       // savedCredentials[key] = JSON.stringify(obj);
//     } else {
//       savedCredentials['lang'] = 'en';
//     }

//     this._localStorage.setCredentials(savedCredentials);
//   }

  getSavedLang() {
    return this._localStorage.getSavedLang();
  }

  resetPassword(form: any) {
     return this.http
      .post<any>(environment.API_URL.Accountkit.resetPassword, form )
      .pipe( 
        map(response => response)
      );
  }

  forgotPassword(form: any) {
     return this.http
      .post<any>(environment.API_URL.Accountkit.forgotPassword, form )
      .pipe( 
        map(response => response)
      );
  }

  validateResetPasswordLink(token: any){
    return this.http
      .get<any>(environment.API_URL.Accountkit.validateResetPasswordLink+ token)
      .pipe(
        map(response => response),
        catchError(error => of({ IsSuccess: false, Message: "Password reset link is not vaild"}))
      )

      
  }
  private handleError(error: any) {
    return of(error.error);
  }

}
