import { Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, Event, NavigationError } from '@angular/router';
import { of } from 'rxjs';
import * as StackTraceParser from 'error-stack-parser';

@Injectable()
export class ErrorsService {
  constructor(private injector: Injector, private router: Router) {
    const self = this;
    // Subscribe to the NavigationError
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationError) {
        // Redirect to the ErrorComponent
        //  this.router.navigate(['/error'])
        this.log(event.error).subscribe((errorWithContext) => {
          return self.router.navigate(['/error'], { queryParams: errorWithContext });
        });
      }
    });
  }

  log(error:any) {
    // Log the error to the console
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    return  of(errorToSend);
    // return FakeHttpService.post(errorToSend);
  }

  addContextInfo(error: any) {
    // You can include context details here (usually coming from other services: UserService...)
    const name = error.name || null;
    const appId = 'Id';
    const user = 'user';
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const statusText = error.statusText;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

    const errorWithContext = { name, appId, user, time, id, url, status, statusText, message, stack };
    return errorWithContext;
  }
}

// class FakeHttpService {
//   static post(error): Observable<any> {
//     console.log('Error sent to the server: ', error);
//     return of(error);
//   }
// }
