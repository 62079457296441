import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiHandler {
  constructor(private http: HttpClient) {}

  private formatErrors(error: any) {
    // console.error(error); // log to console instead
    // return Observable.throw(error.error); // use this for subscribe(error:) to fire
    // Let the app keep running by returning an empty result.
    return of(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(/^(http|https|ftp)/.test(path) ? `${path}`
     : `${environment.API_URL}${path}`, 
     JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }
  postdev(path: string, body: Object = {}, headers: any = {}): Observable<any> {
    headers["Content-Type"] = "application/json";
    return this.http.post(/^(http|https|ftp)/.test(path) ? `${path}` : `${environment.API_URL}${path}`,
      JSON.stringify(body),
      { headers: headers },
        ).pipe(catchError(this.formatErrors));
  }
  delete(path: any): Observable<any> {
    return this.http.delete(`${environment.API_URL}${path}`).pipe(catchError(this.formatErrors));
  }
}
