import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Authenticationservice } from '../authentication/authenticationService';
import { LoggerService } from '../services/logger.service';
import { UserService } from 'src/app/shared/services/http/user/user.service';
//import { map } from 'jquery';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private authService: Authenticationservice, private router: Router, private logger: LoggerService,
    private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkUser(next, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkUser(route, state);
  }

  private checkUser(_route: any, state: any): Observable<boolean> {
    if (this.authService.isAuthenticated()) {
      if (this.router.url !== "/auth/login") {
        var dealerInfo: any = this.userService.UserDetailsList;
        return this.userService.checkPartnerSystemForDealer(dealerInfo.Id).pipe(map((response: any) => {
          if (response == false) {
            this.router.navigate(['dashboard']);
            return false;
          }
          else {
            return true;
          }
        }));
      }     
      return of(true);
    }
    else {
      this.logger.log('Not authenticated, redirecting...');
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return of(false);
    }
  }
}
