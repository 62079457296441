export const PAGINATION_LENGTH = 20;
export const MAX_EVENTS = 4;
export const CalendarStartYear = 2020;

export const APP_ROLES = {
  SuperAdmin: 'Super Admin',
  Customer: 'Customer',
  Staff: 'Staff'
};

export const APP_ROLES_CONSTANT = {
  SuperAdmin: 1,
  Staff: 2,
  Customer: 3
};
