import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Authenticationservice } from '../authentication/authenticationService';

@Injectable({
  providedIn: 'root'
})
export class HttpTokenInterceptor implements HttpInterceptor {
   headersConfig:any;
  constructor(private authService: Authenticationservice) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     this.headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const token = "Bearer " + this.authService.getToken();
    const key = this.authService.getKey();
    if (token) {
      this.headersConfig['AuthToken'] = `${token}`;
      this.headersConfig['AuthKey'] = `${key}`;
    }

    const request = req.clone({ setHeaders: this.headersConfig });

    return next.handle(request);
  }
}
