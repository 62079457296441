import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute , Router, ParamMap} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // isLoading:false;
  title = 'cellgateweb';
  name = 'Translation';
  constructor(public translate: TranslateService,private route : ActivatedRoute, private router : Router) {

  }

  ngOnInit(): void {
    
  }
}
