import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { publish,refCount} from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _notification: BehaviorSubject<string> = new BehaviorSubject<any|null>(null);
  readonly notification$: Observable<string> = this._notification.asObservable().pipe(publish(), refCount());
  private notificationConfig: {};
  constructor(public toastr: ToastrService) {
    this.notificationConfig = {
      enableHtml: true,
      closeButton: true,
      newestOnTop: true,
      maxShown: 1,positionClass:"toast-bottom-right",timeOut:3000
    };
  }

  notifySuccess(m: any) {
    setTimeout(() => this.toastr.success(m, 'SUCCESS', this.notificationConfig));
  }

  notifyError(m: any) {
    setTimeout(() => this.toastr.error(m, 'ERROR', this.notificationConfig));
  }

  notifyWarning(m: any) {
    setTimeout(() => this.toastr.warning(m, 'WARNING', this.notificationConfig));
  }

  notifyInfo(m: any) {
    setTimeout(() => this.toastr.info(m, 'INFO', this.notificationConfig));
  }
}
